import { ArrowLeft, ArrowRight, Discover, Framer } from "iconsax-react";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./case-studies.css";
import cnc from "../../assets/images/case-studies/cnc.png";
import press from "../../assets/images/case-studies/press.png";
import robotic from "../../assets/images/case-studies/robotic.png";
import kpi from "../../assets/images/case-studies/kpi.png";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { BsArrowUpRight, BsPlus } from "react-icons/bs";
import OutsideClickHandler from "react-outside-click-handler";

export const caseStudies: Array<{
  title: string;
  content: string[];
  image: string;
  index: number;
  href: string;
}> = [
    {
      title: "Robotic Welding Shop Monitoring",
      content: [
        "In a mass production activity, tracking of work orders with respect to quantity and the actual cycle time is very critical.",
        "WebEye IOT has helped our client in maintaining precise data with respect to these parameters.",
        "Getting realtime updates makes the decision making faster decreasing the downtime hence increasing productivity.",
        "Our customer can now easily track their work orders in such a mass production activity.",
        "Various customized performance dashboards have been developed by us to help our client in their goal of continious improvement."
      ],
      image: "https://www.metalworkingworldmagazine.com/files/2020/03/Apertura.jpg",
      index: 0,
      href: "#robotic",
    },

    {
      title: "KPI Monitoring Solution for Furnace",
      content: [
        "For this application our client was in need of monitoring the key process parameters for a typical furnace application.",
        "We have coupled our WebEye IOT gateway to the PLC of their furnace in such a way that during every charging cycle, the key process parameters are monitored and saved in our database.",
        "This helps our cliend in maintaining the quality of the output.",
        "This system also has a realtime designation based escalation program if any of these key parameters have been changed in any of the production cycle.",
        "Provided we have a PLC on the machines, key process parameters of any industrial asset can be monitored remotely using WebEye IOT."
      ],
      image: "https://d2qgx4jylglh9c.cloudfront.net/en/wp-content/uploads/2017/09/How-to-Make-Steel-with-an-Oldie-but-Goodie-Blast-Furnace.jpg",
      index: 1,
      href: "#kpi",
    },
    {
      title: "Press Shop Monitoring Solution",
      content: [
        "Press shop is traditionally a labour oriented task.",
        "Eliminating the task of operators updating their daily production sheet acts as boon in increasing the productivity",
        "Die change-over times can be closely monitored.",
        "Realtime alerts for periodic die-polishing can be generated.",
        "Using WebEye IOT, our client has managed to streamline the periodic die change and polishing cycles. This has resulted in aroung 15 percent increase in productivity."
      ]
      ,
      image: "http://www.bestex.co.in/PRESS-SHOP/PRESS-SHOP.jpg",
      index: 2,
      href: "#press",
    },
    {
      title: "CNC/VMC Monitoring Solution",
      content: ['Productivity monitoring is traditionally done by collecting all the data through hardcopies of daily production sheets.', 'These production sheets are updated by operators', 'WebEye IOT solution replaces this method by getting all the productivity parameters directly from the machining centre.', "This helps in reducing the errors and also makes the entire process faster.", "OEE and Downtime trend charts can be generated within few clicks on the computer system.", "Precise and realtime data can be seen using WebEyeIOT which helps in increasing the productivity upto 10-15 percent."],
      image: "https://www.uti.edu/images/default-source/racetrack-pages/common-types-of-cnc-machines.jpg?sfvrsn=5297b630_1",
      index: 3,
      href: "#cnc",
    },


  ];

const CaseStudies = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [activeCaseStudy, setActiveCaseStudy] = useState<number | null>(null);

  useEffect(() => {
    if (activeCaseStudy !== null) {
      document.body.style.overflow = "hidden"
    } else document.body.style.overflow = "unset"
  }, [activeCaseStudy]);

  const CaseStudiesItem = ({
    title,
    image,
    content,
    index,
    href,
  }: {
    title: string;
    image: string;
    content: string[];
    index: number;
    href: string;
  }) => {
    return (
      <div className="case-studies-item" id={href.replace("#", "")}>
        <div className="case-studies-item-img">
          <img src={image} alt="img" />
        </div>
        <div className="case-studies-item-content">
          <h4 className="tan text-lg font-extrabold tracking-wide">{title}</h4>
          <br />
          <p
            className="content normal-text-sm"
          >
            <ul>
              {content.slice(0, 2).map(c => <li>{c}</li>)}
            </ul>
          </p>
          <p className="primary my-2 font-medium text-sm tracking-wide underline underline-offset-4 cursor-pointer" onClick={() => setActiveCaseStudy(index)}>
            Read more
          </p>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="case-studies-container">
        <section className="case-studies-inner">
          <div className="case-studies-fixed">
            {/* <p className="caps primary">CASE STUDIES {activeCaseStudy}</p>
          <br /> */}
            <div>
              <h1 className="h1 tan">Case Studies</h1>

              <br />
              <p className="grey text-md">
                WebEye can be installed and operated within a variety of use cases
              </p>
            </div>

            <div className="case-study-grid mt-4 mb-4">
              <div className="case-study-grid-item">
                <p className="tan h1">15+</p>
                <p className="grid-desc">happy clients</p>
              </div>
              <div className="case-study-grid-item">
                <p className="tan h1">100%</p>
                <p className="grid-desc">track record</p>
              </div>
              <div className="case-study-grid-item">
                <p className="tan h1">5</p>
                <p className="grid-desc">employees</p>
              </div>
              <div className="case-study-grid-item">
                <p className="tan h1">15+</p>
                <p className="grid-desc">happy clients</p>
              </div>
            </div>

            <div className="web-only">
              <small className="grey text-xsm tracking-wide opacity-60">
                Jump to ...
              </small>
              <ul className="mt-2">
                {caseStudies.map((cs, index) => {
                  return (
                    <li
                      onClick={() => setActiveCaseStudy(index)}
                      className={
                        activeCaseStudy === cs.index
                          ? "liactive flex items-center"
                          : "flex items-center"
                      }
                    >
                      <Link
                        to={cs.href}
                        className={
                          activeCaseStudy === cs.index ? "primary" : "grey"
                        }
                      >
                        {cs.title}
                      </Link>
                      {/* <BsArrowUpRight
                      size={16}
                      className={`${
                        activeCaseStudy === cs.index ? "primary" : "grey"
                      } ml-2`}
                    /> */}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="case-studies-content">
            {caseStudies.map((caseStudy, index) => {
              return (
                <CaseStudiesItem
                  index={index}
                  title={caseStudy.title}
                  content={caseStudy.content}
                  image={caseStudy.image}
                  key={index}
                  href={caseStudy.href}
                />
              );
            })}
          </div>
        </section>

      </div>

      <div className={`case-study-overlay transition-all ${activeCaseStudy !== null ? 'is-active' : ''}`}>
        <div className="case-study-overlay-inner">
          {activeCaseStudy !== null && <OutsideClickHandler onOutsideClick={() => setActiveCaseStudy(null)}>
            <div className="case-study-white">
              <div className="w-8 h-8" onClick={() => setActiveCaseStudy(null)}>
                <BsPlus size={32} className="grey opacity-75 hover:opacity-100 cursor-pointer rotate-45" />
              </div>

              <p className="h2 tan my-4">{caseStudies[activeCaseStudy].title}</p>
              <img src={caseStudies[activeCaseStudy].image} alt="" className="w-full rounded-md" />
              <ul>
                {caseStudies[activeCaseStudy].content.map(c => <li className="normal-text-sm mb-3 tan opacity-80">{c}</li>)}
              </ul>
            </div>
          </OutsideClickHandler>}
        </div>


      </div>
    </Fragment>

  );
};

export default CaseStudies;
