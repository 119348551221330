import React, { Fragment, useRef } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { HashRouter, Route, Routes } from "react-router-dom";
import AppWrapper from "./components/AppWrapper/AppWrapper";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import "./globals.css";
import CaseStudies from "./screens/CaseStudies/CaseStudies";
import ContactUs from "./screens/ContactUs/ContactUs";
import Landing from "./screens/Landing/Landing";
import Testimonials from "./screens/Testimonials/Testimonials";
import Gallery from "./screens/Gallery/Gallery";
import About from "./screens/About/About";
import NotFound from "./screens/NotFound/NotFound";
// import { Redirect } from "react-router-dom";

const App = () => {
  const containerRef = useRef(null);
  return (
    // <LocomotiveScrollProvider
    //   options={{
    //     smooth: true,
    //   }}
    //   watch={[]}
    //   containerRef={containerRef}
    // >
    <main ref={containerRef}>
      <Fragment>
        {/* <AppWrapper> */}
        <HashRouter>
          <Header />

          <Routes>
            <Route path="/">
              <Route index element={<Landing />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/case-studies" element={<CaseStudies />} />
              <Route path="/clients-testimonials" element={<Testimonials />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/about" element={<About />} />
              <Route path="*" element={<NotFound />} />
              {/* <Redirect to="/404" /> */}
              {/* <Route path="*" element={<Header />} /> */}
            </Route>
          </Routes>
          <Footer />
        </HashRouter>
        {/* </AppWrapper> */}
      </Fragment>
    </main>
    // </LocomotiveScrollProvider>
  );
};

export default App;
