import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sendGetBackEmail } from "../../utils/sendGetBackEmail";
import "./footer.css";

const Footer = () => {

  const [email, setEmail] = useState('')
  return (
    <div className="footer-container">
      <section className="footer-top">
        <div className="footer-top-left">
          <div className="footer-logo">
            <img src="" alt="" />
            <p className="font-bold h4 tan">WebEye IoT</p>
          </div>
          <br />
          <p className="normal-text-sm grey">
            <i> WebEye IoT is an Industry 4.0 based product that helps in remote
              realtime monitoring of systemsWebEye IOT is an Industry 4.0 based product that remotely monitors systems, reports machine activites to you on your mobile phone, tab or PC.
            </i>
            <br />
            <br />


            &copy; <span className="normal-text-xsm">Copyright 2022. All rights reserved.</span>
          </p>
        </div>
        <div className="footer-top-right">
          {/* <div className="footer-top-right-col">
            <h1 className="h5 tan mb-6">Product</h1>
            <Link to="/about" className="normal-text-sm grey">
              Features
            </Link>
            <Link to="/case-studies" className="normal-text-sm grey">
              Case Studies
            </Link>
            <Link to="/gallery" className="normal-text-sm grey">
              Gallery
            </Link>
            <Link to="/clients-testimonials" className="normal-text-sm grey">
              Clients and Testimonials
            </Link>
          </div>
          <div className="footer-top-right-col">
            <h1 className="h5 tan mb-6">Company</h1>
            <Link to="/about#faq" className="normal-text-sm grey">
              FAQ and Support
            </Link>
            <Link to="/contact-us" className="normal-text-sm grey">
              Contact Us
            </Link>
            <a
              href="https://www.google.com/maps?ll=18.510721,73.790412&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=11267207649504704655"
              className="normal-text-sm grey"
              target="_blank"
            >
              Sitemap
            </a>
          </div> */}
          <div className="footer-top-right-col">
            <p className="h5 tan">Not sure about WebEye?</p>
            <p className="normal-text-sm grey mt-2">Drop your email and we'll get back to you!</p>
            <br />
            <div className="flex items-center landing-mail">
              <form onSubmit={(e) => {
                sendGetBackEmail(email, e)
                setEmail('')
              }}>
                <input onChange={e => setEmail(e.target.value)} value={email} required type="email" placeholder="your-email@email.com" className="landing-email" />
                <button className="primary-button pb big ml-2" type="submit">Contact me</button>
              </form>

            </div>
          </div>
        </div>
      </section>
      <section className="footer-bottom-links">
        {/* <p className="tan normal-text-sm">Quick Links</p>
        <br /> */}
        <div className="footer-bottom-links-grid">
          <Link to="/about" className="normal-text-sm grey">
            Features
          </Link>
          <Link to="/case-studies" className="normal-text-sm grey">
            Case Studies
          </Link>
          <Link to="/gallery" className="normal-text-sm grey">
            Gallery
          </Link>
          <Link to="/clients-testimonials" className="normal-text-sm grey">
            Clients and Testimonials
          </Link>
          <Link to="/about#faq" className="normal-text-sm grey">
            FAQ and Support
          </Link>
          <Link to="/contact-us" className="normal-text-sm grey">
            Contact Us
          </Link>
          <a
            href="https://www.google.com/maps?ll=18.510721,73.790412&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=11267207649504704655"
            className="normal-text-sm grey"
            target="_blank"
          >
            Sitemap
          </a>
        </div>
      </section>
      <section className="footer-bottom">
        <p className="font-normal normal-text-sm grey lg:text-center">
          Designed and developed by{" "}
          <a
            href="https://yatishkelkar.netlify.com"
            target="_blank"
            className="primary"
          >
            Yatish Kelkar
          </a>
        </p>
      </section>
    </div>
  );
};

export default Footer;
