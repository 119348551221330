import { ArrowRight } from "iconsax-react";
import React from "react";
import { AiFillInfoCircle, AiOutlineInfoCircle } from "react-icons/ai";
import {
  BsChevronRight,
  BsFillChatFill,
  BsFillChatTextFill,
  BsGridFill,
  BsPeopleFill,
} from "react-icons/bs";
import { CgFormatSlash } from "react-icons/cg";
import { Link } from "react-router-dom";
import "./not-found.css";

interface SuggestPage {
  title: string;
  desc: string;
  icon: any;
  url: string;
}

const suggestPages: Array<SuggestPage> = [
  {
    title: "Case Studies",
    desc: "Know how WebEye can be used",
    url: "/case-studies",
    icon: <BsGridFill size={18} className="primary" />,
  },
  {
    title: "About WebEye",
    desc: "Know more about WebEye IoT and FAQs",
    url: "/about",
    icon: <AiFillInfoCircle size={20} className="primary" />,
  },
  {
    title: "Clients and Testimonials",
    desc: "Check out our clients, and what they have to say about WebEye",
    url: "/clients-testimonials",
    icon: <BsPeopleFill size={18} className="primary" />,
  },

  {
    title: "Contact Us",
    desc: "Get in touch with us",
    url: "/contact-us",
    icon: <BsFillChatTextFill size={18} className="primary" />,
  },
];

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="tan h1">This page does not exist</h1>
      <br />
      <p className="grey normal-text-sm">Browse some popular pages below</p>
      <br />
      <br />
      {suggestPages.map((suggest: SuggestPage, index: number) => {
        return (
          <Link
            key={index}
            to={suggest.url}
            className="border-b border-slate-200 h-20 py-2 w-full lg:w-2/5 flex items-center transition-all justify-between px-0"
          >
            <div className="flex items-center">
              <div className="green-box mr-4 h-12 w-12 rounded-md flex items-center justify-center">
                {suggest.icon}
              </div>
              <div className="flex flex-col">
                <p className="normal-text-lg tan font-extrabold">
                  {suggest.title}
                </p>
                <p className="tan opacity-60 desc mt-0.5">{suggest.desc}</p>
              </div>
            </div>
            <BsChevronRight size={12} className="grey ml-2 lg:ml-12" />
          </Link>
        );
      })}
      <Link
        to="/"
        className="go-home text-sm flex items-center primary mt-4 w-full lg:w-2/5 font-medium align-left"
      >
        Or, go back home
        <ArrowRight size={16} className="primary ml-2" />
      </Link>
    </div>
  );
};

export default NotFound;
