import cncIcon from "../../assets/images/landing/cnc.svg";
import compressorIcon from "../../assets/images/landing/compressor.svg";
import {
  AiOutlineDotChart,
  AiOutlineException,
  AiOutlineFall,
  AiOutlineFileSync,
} from "react-icons/ai";

interface Uses {
  title: string;
  description: string;
  imageLink?: string;
}

interface Testimonial {
  content: string;
  byWhom: string;
  jobRole: string;
  lineClamp: number;
  jobWhere: string;
  photo: string;
}

interface Feature {
  title: string;
  description: string;
  icon: any;
}

interface TeamMember {
  name: string;
  role: string;
  photo: string;
}

const uses: Array<Uses> = [
  {
    title: "Connect",
    imageLink: cncIcon,
    description:
      "Every machine is fitted with a WebEye Gateway which is coupled with the machine controller",
  },
  {
    title: "Collect",
    imageLink: compressorIcon,
    description:
      "This helps in uploading and storing the data to a secure cloud space",
  },
  {
    title: "Kaizen",
    imageLink: cncIcon,
    description:
      "The data stored, when analyzed can give the machine downtimes, operator efficiency, machine utilization, plant utilization and OEE reports",
  },
];
// const uses: Array<Uses> = [
//     {
//         title: 'CNC and VMC',
//         imageLink: cncIcon,
//         description: 'Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.'
//     },
//     {
//         title: 'Compressor',
//         imageLink: compressorIcon,
//         description: 'Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.'
//     },
//     {
//         title: 'CNC and VMC',
//         imageLink: cncIcon,
//         description: 'Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.'
//     },
//     {
//         title: 'Compressor',
//         imageLink: compressorIcon,
//         description: 'Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.'
//     },
// ]

const testimonials: Array<Testimonial> = [
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work. Our experience with WebEye was absolutely fantastic and we are looking forward to use it more in our shopfloors",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 4,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 2,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 6,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 3,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 5,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 4,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 4,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 4,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 4,
  },
];

const testimonialsDetail: Array<Testimonial> = [
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work. Our experience with WebEye was absolutely fantastic and we are looking forward to use it more in our shopfloors",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 4,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 2,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 6,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 3,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 5,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 4,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 4,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 4,
  },
  {
    content:
      "Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work.",
    byWhom: "Ramesh Kulkarni",
    photo: "https://picsum.photos/400/500",
    jobRole: "Plant Manager",
    jobWhere: "Bajaj Systems",
    lineClamp: 4,
  },
];

const features: Array<Feature> = [
  {
    title: "Real time analysis of data captured",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    icon: <AiOutlineDotChart size={30} color="cornflowerblue" />,
  },
  {
    title: "Downtime Reason Dashboard",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    icon: <AiOutlineFall size={30} color="salmon" />,
  },
  {
    title: "Preventive Maintainence Info",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    icon: <AiOutlineException size={30} color="gold" />,
  },
  {
    title: "Factory Performance Report",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    icon: <AiOutlineFileSync size={30} color="green" />,
  },
];

const teamMembers: Array<TeamMember> = [
  {
    name: "Padmanabh Joglekar",
    photo: "https://picsum.photos/200/200",
    role: "Founder & CEO",
  },
  {
    name: "Elisabeth Hale",
    photo: "https://picsum.photos/100/100",
    role: "Manager, IoT Solutions",
  },
  {
    name: "Shankar Murthy",
    photo: "https://picsum.photos/600/600",
    role: "Finance Department",
  },
  {
    name: "Indrani Banerjee",
    photo: "https://picsum.photos/700/700",
    role: "Investor - India",
  },
  {
    name: "Harish Thampy",
    photo: "https://picsum.photos/400/400",
    role: "Legal Department",
  },
  {
    name: "Noor Imam",
    photo: "https://picsum.photos/300/300",
    role: "Investor - Japan",
  },
];

export { uses, testimonials, features, testimonialsDetail, teamMembers };

export type { Uses, Testimonial, Feature, TeamMember };
