import { BsCheck2, BsEnvelope, BsTelephone } from "react-icons/bs";
import React, { FormEvent, useEffect, useState } from "react";
import "./contact-us.css";
import emailjs from '@emailjs/browser';

const ContactUs = () => {

  let transporter: any;

  const helpYouWithOptions = [
    "Interested in learning more about WebEye",
    "I want to see a demo of WebEye",
    "Interesting in purchasing WebEye",
    "I’m facing a problem with the WebEye setup",
    "Something else",
  ];
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    helpYouWith: [helpYouWithOptions[0]],
    message: "",
  });

  const helpYouOnChange = (index: number) => {
    if (form.helpYouWith.includes(helpYouWithOptions[index])) {
      setForm({
        ...form,
        helpYouWith: form.helpYouWith.filter(
          (option) => option !== helpYouWithOptions[index]
        ),
      });
    } else {
      let newArr = form.helpYouWith;
      newArr.push(helpYouWithOptions[index]);
      setForm({
        ...form,
        helpYouWith: newArr,
      });
    }
    console.log(form.helpYouWith);
  };



  const onFormSubmit = async (event: FormEvent) => {
    console.log(form)
    event.preventDefault()
    emailjs.send(
      'webeye',
      "template_atgvx46",
      {
        first_name: form.firstName,
        last_name: form.lastName,
        email: form.email,
        phone: form.contact,
        needs_help: form.helpYouWith.join(" , "),
        message: form.message
      },
      "RmXrqkPr0WimdRNOe"
    ).then(res => {
      console.log('Email successfully sent!', res)
      alert("Message sent successfully!")
    })
      .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  }, []);

  return (
    <div className="contact-us-container">
      <div className="contact-us-left">
        <h1 className="h1">Get in touch with us</h1>
        <br />
        <p className="grey normal-text-md">
          Enquire about WebEye, about an estimate or about other issues
        </p>
        <br />
        <p className="h6 tan my-2">India Office</p>
        <p className="grey normal-text-xsm">
          C2-302, Saudamini Commercial Complex, Bhusari Colony,Near Chandani
          Chowk, Paud Road, Pune 411038 Maharashtra State, India
        </p>
        <div className="flex items-center mt-4">
          <BsTelephone size={15} className="grey mr-4" />
          <a href="tel:+91-20-25283582" className="normal-text-xsm grey">
            +91-20-25283582
          </a>
        </div>
        <div className="flex items-center mt-2">
          <BsEnvelope size={15} className="grey mr-4" />
          <a href="mailto:info@sanminsys.com" className="normal-text-xsm grey">
            info@sanminsys.com
          </a>
        </div>
        <p className="h6 tan mt-6">Japan Office </p>
        <p className="grey normal-text-xsm">
          株式会社サンミン
          <br />
          405 Mitaka Sangyo Plaza, 3-38-4 Shimorenjaku,Mitaka, Tokyo
          181-0013,Japan
        </p>
        <div className="flex items-center mt-4">
          <BsTelephone size={15} className="grey mr-4" />
          <a href="tel:++81-422-477551" className="normal-text-xsm grey">
            +81-422-477551
          </a>
        </div>
        <div className="flex items-center mt-2">
          <BsEnvelope size={15} className="grey mr-4" />
          <a href="mailto:info@sanminsys.com" className="normal-text-xsm grey">
            info@sanminsys.com
          </a>
        </div>
        <p className="grey normal-text-sm mt-8">
          Looking for directions? Find it on{" "}
          <a
            href="https://www.google.com/maps?ll=18.510721,73.790412&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=11267207649504704655"
            target="_blank"
            className="normal-text-sm primary font-medium"
          >
            Google Maps
          </a>
        </p>
      </div>
      <div className="contact-us-right">
        <form onSubmit={onFormSubmit}>
          <section>
            <label htmlFor="firstname">Name</label>
            <input
              type="text"
              name="firstname"
              required
              value={form.firstName}
              placeholder="First Name"
              onChange={(e) => setForm({ ...form, firstName: e.target.value })}
            />
          </section>
          <section>
            <label htmlFor="lastname" style={{ color: "white" }}>
              {" " + "o"}
            </label>
            <input
              type="text"
              name="lastname"
              required
              value={form.lastName}
              placeholder="Last Name"
              onChange={(e) => setForm({ ...form, lastName: e.target.value })}
            />
          </section>
          <section>
            <label htmlFor="email">Email ID</label>
            <input
              type="text"
              name="email"
              required
              value={form.email}
              placeholder="example@gmail.com"
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </section>
          <section>
            <label htmlFor="contact">Contact Number</label>
            <input
              type="text"
              name="contact"
              required
              value={form.contact}
              placeholder="XXXXXXXXXX"
              onChange={(e) => setForm({ ...form, contact: e.target.value })}
            />
          </section>
          <section>
            <label>
              What can we help you with?{" "}
              <span className="grey text-sm">(select all that apply)</span>
            </label>
            <div className="options-cont">
              {helpYouWithOptions.map((option, index) => {
                return (
                  <div
                    className="option rounded-md"
                    onClick={() => helpYouOnChange(index)}
                    key={index}
                  >
                    <div
                      className={
                        form.helpYouWith.includes(option)
                          ? "sq sq-active"
                          : "sq"
                      }
                    >
                      {form.helpYouWith.includes(option) && (
                        <BsCheck2 color="white" size={12} />
                      )}
                    </div>
                    <p className="tan text-sm">{option}</p>
                  </div>
                );
              })}
            </div>
          </section>
          <section>
            <label htmlFor="contact">Message</label>
            <textarea
              name="message"
              value={form.message}
              required
              placeholder="Enter your message here"
              onChange={(e) => setForm({ ...form, message: e.target.value })}
            ></textarea>
          </section>
          <button className="primary-button" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
