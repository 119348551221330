import React, { Fragment, useEffect, useRef, useState } from "react";
import Cube from "../../components/Cube/Cube";
import LandingTestimonial from "../../components/LandingTestimonial/LandingTestimonial";
import "./landing.css";
import founder from "../../assets/images/landing/mr-joglekar.svg";
import {
  Testimonial,
  testimonials,
  Uses,
  uses,
  teamMembers,
  TeamMember,
} from "./landingInfo";
import Xarrow from "react-xarrows";
import { FiChevronDown } from "react-icons/fi";
import { Link } from "react-router-dom";

import play from "../../assets/icons/play.svg";
import heroVideo from "../../assets/videos/ppt.mp4";
import StatBox from "../../components/StatBox/StatBox";
import Map from '../../components/Map/Map'
import { brandList } from "../../utils/brands";
import { GoQuote } from 'react-icons/go'
import { ArrowRight } from "iconsax-react";
import indiaMap from '../../assets/images/landing/india-locs.svg'
import arrowMap from '../../assets/images/landing/arrow-map.svg'
import productionDashboard from '../../assets/images/landing/production-dashboard.svg'
import vidbg from "../../assets/images/landing/vid-bg.png";
import { sendGetBackEmail } from "../../utils/sendGetBackEmail";

interface Feature {
  title: string;
  desc: string;
  img: string;
}

const painPoints: Array<string> = [
  "Lack of realtime data transfer",
  "Information Challenge",
  "Machine Capacity Utilization",
  "Global Competition",
  "Workforce Challenges",

]

const features = [
  {
    title: "Production Dashboard",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit sed do conseter miso",
    img: productionDashboard,
  },
  {
    title: "Factory Performance Dashboard",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    img: productionDashboard,
  },
  {
    title: "Downtime Analysis",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    img: productionDashboard,
  },
  {
    title: "OEEE Dashboard",
    desc: "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    img: productionDashboard,
  },
];

interface MapInfo {
  coordinateX: number,
  coordinateY: number,
  title: string,
  companies: Array<string>
}

const mapInfo: Array<MapInfo> = [
  {
    coordinateX: 138,
    coordinateY: 130,
    title: "Ludhiana",
    companies: ["Bajaj Sons"]
  },
  {
    coordinateX: 179,
    coordinateY: 152,
    title: "Delhi",
    companies: ["PICL"]
  },
  {
    coordinateX: 91,
    coordinateY: 246,
    title: "Vapi",
    companies: ["Baumer Technologies Pvt. Ltd"]
  },
  {
    coordinateX: 111,
    coordinateY: 300,
    title: "Mumbai",
    companies: ["Gala Spring", "Avion Industries"]
  },
  {
    coordinateX: 139,
    coordinateY: 320,
    title: "Pune",
    companies: ["Radheya Machining", "Posh Metals"]
  },
  {
    coordinateX: 160,
    coordinateY: 294,
    title: "Aurangabad",
    companies: ["Dhananjay Group", "Praneel Industries", "Laxmi Industries", "Ganesh Press Pvt. Ltd"]
  },
  {
    coordinateX: 139,
    coordinateY: 279,
    title: "Nashik",
    companies: ["Ring Plus Aqua"]
  },
  {
    coordinateX: 172,
    coordinateY: 407,
    title: "Bengaluru",
    companies: ["MotherIndia", "SJF"]
  },
  {
    coordinateX: 213,
    coordinateY: 428,
    title: "Chennai",
    companies: ["BSH"]
  },
]

const Landing = () => {
  const [videoActive, setVideoActive] = useState<boolean>(false);
  const [activeMapLoc, setActiveMapLoc] = useState<MapInfo>(mapInfo[0])
  const videoRef = useRef<HTMLVideoElement>(null);
  const [email, setEmail] = useState('')

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  }, []);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div className="landing-container">
      <div className="landing-hero-bg">
        <div className="landing-hero-section flex flex-col w-full">
          <div
            className={`${isMobile ? "my-4 h-full" : ""
              } landing-hero-top flex justify-between w-full`}
          >
            <div className="landing-hero-top-left flex flex-col">
              {isMobile ? (
                <p className="main-title tan font-bold">
                  With{"  "}
                  <span className="fancy-underline font-bold main-title">
                    WebEye IoT,
                  </span>
                  {"  "}
                  innovation is always at the edge
                </p>
              ) : (
                <p className="main-title tan font-bold mt-8">
                  With{"  "}
                  <span className="fancy-underline font-bold main-title">
                    WebEye IoT,
                  </span>
                  {"  "}
                  innovation is always at the edge
                </p>
              )}
              <div className="h-2"></div>
              {isMobile && <div className="landing-video">
                {videoActive ? null : (
                  <div
                    className="video-overlay"
                    onClick={() => {
                      // setVideoActive(true);
                      videoRef.current?.play();
                    }}
                  >

                    <div className="play-button-mob cursor-pointer">
                      <div className="play-button">
                        <img src={play} alt="play" />
                      </div>
                      <p className="text-white normal-text-xsm">Know more about WebEye</p>
                    </div>

                  </div>
                )}
                {
                  videoActive ?
                    <video
                      ref={videoRef}
                      src={videoActive ? heroVideo : heroVideo + "#t=8.2"}
                      autoPlay={videoActive ? true : false}
                      controls={videoActive}
                      loop
                      muted
                      className="w-full"
                    ></video>

                    : <img src={vidbg} className="h-full object-cover" alt="" />
                }

              </div>}
              <br />
              {isMobile ? (
                <p className="normal-text-lg grey">
                  WebEye IOT is an Industry 4.0 based product that remotely
                  monitors systems, reports machine activites to you on your{" "}
                  mobile phone, tab or PC.
                </p>
              ) : (
                <p className="normal-text-xlg dark-grey mt-4 mb-4">
                  WebEye IOT is an Industry 4.0 based product that helps in
                  remotely monitor systems, reports machine activites to you
                  wherever you are, whenever you want, on your mobile phone, tab
                  or PC.
                </p>
              )}
              <br />
              <div className="flex flex-col lg:flex-row items-center">
                <Link to="/contact-us">
                  <button className="primary-button big">Enquire about WebEye</button>
                </Link>
                {isMobile && (
                  <FiChevronDown
                    size={20}
                    className="grey mt-2"
                    onClick={() =>
                      window.scroll({
                        top: window.innerHeight - 8,
                        left: 0,
                        behavior: "smooth",
                      })
                    }
                  />
                )}
                <div className="w-4 web-only"></div>
                <p className="normal-text-sm grey web-only">or</p>
                <div className="w-4 web-only"></div>
                {/* <Link to="/roi">
                  <button className="secondary-button big">
                    Get ROI with WebEye
                  </button>
                </Link> */}
                <div className="flex items-center w-full landing-mail web-only">
                  <form onSubmit={(e) => {
                    sendGetBackEmail(email, e)
                    setEmail("")
                  }}>
                    <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder="Enter your email, we'll get back to you" className="landing-email" />
                    <button className="primary-button pb big ml-2" type="submit">Contact me</button>
                  </form>

                </div>
              </div>


            </div>
            <div className="landing-hero-top-right">
              <div className="flex justify-between flex-1 mb-12 web-only">
                <div className="landing-video">
                  {videoActive ? null : (
                    <div
                      className="video-overlay"
                      onClick={() => {
                        setVideoActive(true);
                        videoRef.current?.play();
                      }}
                    >
                      {
                        isMobile
                          ?
                          (<div className="play-button">
                            <img src={play} alt="play" />
                          </div>) :
                          <div className="play-button-web cursor-pointer">
                            <div className="play-button">
                              <img src={play} alt="play" />
                            </div>
                            <p className="text-white normal-text-sm">Watch to know more about WebEye</p>
                          </div>
                      }
                    </div>
                  )}
                  {
                    videoActive ?
                      <video
                        ref={videoRef}
                        src={videoActive ? heroVideo : heroVideo + "#t=8.2"}
                        autoPlay={videoActive ? true : false}
                        controls={videoActive}
                        loop
                        muted
                        className="w-full"
                      ></video>

                      : <img src={vidbg} className="h-full object-cover" alt="" />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="landing-hero-bottom mt-6 web-only">
            <p className="normal-text-lg grey">Trusted by 15+ companies</p>
            <div className="landing-companies">
              {brandList.slice(0, 6).map((item, index) => {
                return (
                  <img src={item.imageBlack} key={index} />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="uses-container">
        <div className="mob-only flex flex-col">
          <p className="caps primary text-left">How it Works</p>
          <br />
          <p className="h1">
            <span className="h1 grey">How does</span>{" "}
            <span className="h1 tan">
              <span className="fancy-underline h1 tan"> WebEye IoT </span> work?
            </span>
          </p>
          <br />
        </div>
        <div className="uses-inner-container mob-only">
          {uses.map((use: Uses, index: number) => {
            return (
              <Fragment>
                <div
                  className="uses-item-container p-6 bg-white rounded-md flex flex-col"
                  key={index}
                  id={`uses-item-${index}`}
                >
                  <p className="use-index font-extrabold grey">
                    {index + 1}
                    {isMobile ? "." : null}
                  </p>

                  <p className="use-title tan font-semibold">{use.title}</p>

                  <p className="normal-text-sm grey">{use.description}</p>
                </div>
                {index !== uses.length - 1 && !isMobile && (
                  <Xarrow
                    start={`uses-item-${index}`}
                    end={`uses-item-${index + 1}`}
                    color="#3fc174"
                    strokeWidth={3}
                    headSize={5}
                    dashness={{
                      strokeLen: 7,
                      nonStrokeLen: 5,
                      animation: 0.5,
                    }}
                    path="smooth"
                    curveness={0.5}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </div>

      <div
        className={`flex p-side flex-col text-left mb-6 ${isMobile ? "mt-0" : "mt-6"
          }`}
      >
        <h1 className="h1 tan my-8">
          Realtime stats with{" "}
          <span className="fancy-underline h1 tan my-8">WebEye</span>
        </h1>
        <div className="feats">
          {features.map((f, index) => (
            <FeatBox feature={f} key={index} />
          ))}
        </div>
      </div>


      <div className="landing-fancy-stat">
        <div className="lfs-left">
          {/* <div className="flex items-center">
            <BsChatTextFill size={16} className="text-white mr-4" />
            <p className="text-white h6">Happy clients</p>
          </div> */}
          <GoQuote size={28} className="text-white opacity-70" />
          <p className="normal-text-sm text-white">
            Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work. Our experience with WebEye was absolutely fantastic and we are looking forward to use it more in our shopfloors
          </p>
          <p className="normal-text-xsm text-white opacity-90 mt-6">
            Elisabeth Hale, CEO at BoltIoT
          </p>
        </div>
        <div className="lfs-right">
          <p className="h1 tan">
            Merging internet and factories
          </p>
          <p className="normal-text-sm grey my-8">
            WebEye IOT is an Industry 4.0 based product that remotely monitors systems, reports machine activites to you on your mobile phone, tab or PC.
            <br /><br />
            WebEye IOT is developed by Sanmin Technologies Pvt Ltd, based in Pune, India and in Japan. Lorem ipsum dolor sit amet consectetur dolor amer uber konnen wir with dich eine Zuchenstreite bechreiften. Um die StraBe will sich die Haus im eine folodsech denen.
            <br /><br />
            Lorem ipsum dolor sit amet consectetur dolor amer uber konnen wir with dich eine Zuchenstreite bechreiften. Um die StraBe will sich die Haus im eine folodsech denen.
          </p>
          <div className="lfs-grid">
            <div>
              <p className="normal-text-sm grey">Case Studies</p>
              <p className="tan h1">15+</p>
            </div>
            <div>
              <p className="normal-text-sm grey">Customer Satifaction</p>
              <p className="tan h1">100%</p>
            </div>
            <div>
              <p className="normal-text-sm grey">Case Studies</p>
              <p className="tan h1">4</p>
            </div>
            <div>
              <p className="normal-text-sm grey">Uptime</p>
              <p className="tan h1">99%</p>
            </div>
          </div>
          <Link to="/clients-testimonial" className="mt-6 primary normal-text-lg font-semibold flex items-center">View testimonials from clients <ArrowRight size={18} className="ml-2 primary" /></Link>
        </div>
      </div>


      <div className="landing-map">
        <div className="landing-map-left">
          <p className="h1 tan"><span className="fancy-underline h1 tan">WebEye</span>{" "} is used pan-india</p>
          <br />
          <p className="normal-text-sm grey my-2 lg:my-8">
            WebEye IOT is an Industry 4.0 based product that remotely monitors systems, reports machine activites to you on your mobile phone, tab or PC.
            <br /><br />
            Lorem ipsum dolor sit amet consectetur dolor amer uber konnen wir with dich eine Zuchenstreite bechreiften. Um die StraBe will sich die Haus im eine folodsech denen.
          </p>
          <br />
          <hr />
          <GoQuote className="tan opacity-30 mt-4 lg:mt-8" size={28} />

          <p className="normal-text-sm mt-4 italic tan">
            Lorem ipsum dolor sit amet, We have implemented many precautions within our facility to increase personal safety and health and enhance social distancing in the work. Our experience with WebEye was absolutely fantastic and we are looking forward to use it more in our shopfloors
          </p>
          <div className="flex items-center mt-6">
            <img src="https://picsum.photos/100/100" alt="" className="rounded-full w-6 h-6 mr-2" />
            <p className="normal-text-sm grey"><span className="primary">Elisabeth Hale</span> {" "}- CEO at Bajaj says, </p>
          </div>
        </div>
        <div className="landing-map-right">
          <div className="landing-map-right-inner">
            <img src={arrowMap} className="web-only arrow-map" alt="arrow" />
            <img src={indiaMap} alt="india-map" useMap="india-map" />
            {mapInfo.map((map: MapInfo, index: number) => {
              return <div key={index} className={`web-only map-point-cont ${activeMapLoc.title === map.title ? "active-pt" : ''}`} style={{ top: map.coordinateY - 8, left: map.coordinateX - 8 }} onMouseEnter={() => setActiveMapLoc(map)}>
                <div className="map-point-inner">
                  <div className="map-point"></div>

                  <div className={`map-info ${activeMapLoc.title === map.title ? "" : 'map-no-visible'}`}>
                    <div className="w-full h-full">
                      <p className="tan h6">{map.title}</p>
                      <ul>
                        {
                          map.companies.map(c => <li className="normal-text-sm grey">{c}</li>)
                        }
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>






      {/* Landing Testimonials Section */}
      <div className="landing-testimonial-container">
        <div className="landing-testimonial-container-bg"></div>
        <div className="landing-testimonial-container-white-overlay"></div>
        <div className="landing-testimonial-container-inner">
          <p className="caps primary text-center">Testimonials and Clients</p>
          <br />
          <p className="h1 tan text-center">
            15+ companies use and trust WebEye
          </p>

          <div className="landing-testimonial-container-grid">
            <div className="landing-testimonial-grid-item">
              {testimonials.slice(0, 3).map((t: Testimonial, index: number) => (
                <LandingTestimonial testimonial={t} index={index} />
              ))}
            </div>
            <div className="spacer-2"></div>
            <div className="landing-testimonial-grid-item">
              {testimonials.slice(3, 6).map((t: Testimonial, index: number) => (
                <LandingTestimonial testimonial={t} index={index} />
              ))}
            </div>
            <div className="spacer-2"></div>
            <div className="landing-testimonial-grid-item">
              {testimonials.slice(6, 9).map((t: Testimonial, index: number) => (
                <LandingTestimonial testimonial={t} index={index} />
              ))}
            </div>
          </div>
        </div>
        <a href="/testimonials" className="landing-testimonial-floating-button">
          <button className="secondary-button">
            See all clients and testimonials
          </button>
        </a>
      </div>
      <StatBox />
      <div className="meet-team-container">
        <div className="meet-team-left">
          <p className="caps primary">Team</p>
          <br />
          <p className="h1 tan">Meet our team</p>
          <br />
          <div className="normal-text-sm grey">
            Get to know the team working behind the scenes on WebEye and other
            products at Sanmin
          </div>
        </div>
        <div className="meet-team-right">
          {teamMembers.map((member: TeamMember, index: number) => {
            return (
              <div className="team-member-box" key={index}>
                <img src={member.photo} alt="" />
                <div>
                  <p className="h5 tan">{member.name}</p>
                  <p className="normal-text-sm tan opacity-60">{member.role}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Features container */}
      {/* <div className="features-container">
        <div className="features-left">
          <p className="caps primary text-left">Features</p>
          <br />
          <h1 className="tan h1">
            What can <span className="fancy-underline tan h1">WebEye</span> do?
          </h1>
          <br />
          {!isMobile && (
            <button className="primary-button w-fit">See all features</button>
          )}
        </div>
        <div className="features-grid">
          {features.map((feature: Feature, index: number) => {
            return (
              <div
                className={`bg-white rounded-md ${isMobile ? "p-4" : "p-6"
                  } flex flex-col`}
                key={index}
              >
                {feature.img}
                <br />
                <p className="text-md tan font-semibold">{feature.title}</p>
                <p className="mt-2 text-sm grey normal-text-xsm">
                  {feature.desc}
                </p>
              </div>
            );
          })}
        </div>
        {isMobile && (
          <button className="primary-button w-full mt-6">
            See all features
          </button>
        )}
      </div> */}

      {/* <Map /> */}

      {/* Founder container */}
      {/* <div className="founder-container">
        <p className="caps primary">A Note from the Founder</p>
        <p className="note tan">
          Lorem ipsum dolor sit amet, We have implemented many precautions
          within our facility to increase personal safety and health and enhance
          social distancing in the work. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
        </p>
        <div className="flex flex-row items-center w-fit">
          <img
            src={founder}
            alt="founder"
            className="rounded-full w-12 h-12 mr-4"
          />
          <div className="flex flex-col w-fit">
            <p className="font-semibold tan text-md">Padmanabh Jogalekar</p>
            <p className="font-normal text-sm mt-2 grey">
              Founder and CEO, Sanmin Technologies
            </p>
          </div>
        </div>
        <br />
      </div> */}


      <div className="green-section">
        <p className="h1 text-white">Innovation @ the Edge</p>
        <p className="h1 text-white"><span className="opacity-70 h1 text-white">Seamlessly monitor your systems with </span> <span className="fancy-underline h1 tan opacity-100">WebEye</span></p>
        <br />
        <br />
        <p className="text-white normal-text-lg">WebEye addresses common pain points in manufacturing sector such as -</p>
        <div className="pain-points">
          {
            painPoints.map((p, index) => {
              return <Fragment>
                <div className="pain-point" key={index}>
                  {/* <BsFillExclamationCircleFill size={16} className="text-white opacity-80 mr-2" /> */}
                  <p className="text-white normal-text-sm opacity-50">{p}</p>
                </div>
                <div className="w-4"></div>
              </Fragment>
            })
          }
        </div>

        <div className="landing-fancy-stat lg:mt-12">
          <div className="lfs-left">
            {/* <div className="flex items-center">
            <BsChatTextFill size={16} className="text-white mr-4" />
            <p className="text-white h6">Happy clients</p>
          </div> */}
            <GoQuote size={28} className="text-white opacity-70" />
            <p className="normal-text-md text-white leading-loose">
              Lorem ipsum dolor sit amet, We have implemented many precautions
              within our facility to increase personal safety and health and enhance
              social distancing in the work. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
            </p>
            <div className="flex flex-row items-center w-fit mt-4">
              <img
                src={founder}
                alt="founder"
                className="rounded-full w-10 h-10 mr-4"
              />
              <div className="flex flex-col w-fit">
                <p className="text-white normal-text-sm">Padmanabh Jogalekar</p>
                <p className="font-normal normal-text-xsm text-white opacity-75">
                  Founder and CEO, Sanmin Technologies
                </p>
              </div>
            </div>

          </div>
          <div className="lfs-right">

            <p className="normal-text-sm text-white opacity-60 my-4">
              WebEye IOT is an Industry 4.0 based product that remotely monitors systems, reports machine activites to you on your mobile phone, tab or PC.
              <br /><br />
              WebEye IOT is developed by Sanmin Technologies Pvt Ltd, based in Pune, India and in Japan. Lorem ipsum dolor sit amet consectetur dolor amer uber konnen wir with dich eine Zuchenstreite bechreiften. Um die StraBe will sich die Haus im eine folodsech denen.
              <br /><br />
              Lorem ipsum dolor sit amet consectetur dolor amer uber konnen wir with dich eine Zuchenstreite bechreiften. Um die StraBe will sich die Haus im eine folodsech denen.
            </p>

            <Link to="/clients-testimonial" className="mt-6 primary normal-text-lg font-semibold flex items-center">View testimonials from clients <ArrowRight size={18} className="ml-2 primary" /></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;

const FeatBox = ({ feature }: { feature: Feature }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <div className="about-hero-feat">
      <div className="about-hero-feat-img">
        <img alt="img" src={feature.img} />
      </div>
      <p className={`tan h5 ${isMobile ? "mt-4 my-2" : "my-4"}`}>
        {feature.title}
      </p>
      <p className="feat-desc">{feature.desc}</p>
    </div>
  );
};
