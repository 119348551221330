import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { brandList } from "../../utils/brands";
import "./stat-box.css";

const StatBox = () => {
  return (
    <div className="stat-box">
      <h1 className="white h4">
        An AI-driven product that's loved by our clients
      </h1>
      <div className="stat-box-logos">
        {brandList.slice(0, 5).map((item, index) => {
          return item.url ? (
            <a href={item.url} target="_blank">
              <img
                title={item.name}
                className="w-36"
                src={item.imageWhite}
                key={index}
              />
            </a>
          ) : (
            <img
              title={item.name}
              className="w-36"
              src={item.imageWhite}
              key={index}
            />
          );
        })}
        <Link
          className="text-white flex items-center"
          to="clients-testimonials"
        >
          See all Clients
          <FiChevronRight
            className="ml-2 hover:ml-4 transition-all text-white"
            size={20}
          />
        </Link>
      </div>
    </div>
  );
};

export default StatBox;
