import { Add, ArrowLeft, ArrowLeft2, ArrowRight2 } from "iconsax-react";
import React, { Fragment, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import "./gallery.css";

const images = [
  "https://picsum.photos/500/300",
  "https://picsum.photos/300/300",
  "https://picsum.photos/400/300",
  "https://picsum.photos/700/300",
  "https://picsum.photos/800/300",
  "https://picsum.photos/500/300",
  "https://picsum.photos/500/300",
  "https://picsum.photos/500/300",
];

const Gallery = () => {
  function disableScrolling() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }

  function enableScrolling() {
    window.onscroll = function () {};
  }
  const [activeImage, setActiveImage] = useState<number | null>(null);
  useEffect(() => {
    if (activeImage) {
      disableScrolling();
      console.log(images[activeImage]);
    } else enableScrolling();
  }, [activeImage]);
  return (
    <Fragment>
      <div className="gallery-container flex flex-col w-full p-side">
        <p className="text-4xl my-8 tan font-bold">Gallery</p>
        <div className="gallery-grid">
          {images.map((image, index) => {
            return (
              <img
                src={image}
                key={index}
                onClick={() => setActiveImage(index + 1)}
              />
            );
          })}
        </div>
      </div>

      {(activeImage || activeImage === 0) && (
        <div
          className="gallery-overlay"
          style={{ visibility: activeImage ? "visible" : "hidden" }}
        >
          <div className="gallery-image-view">
            <img
              src={(activeImage && images[activeImage - 1]) || ""}
              alt="img"
            />
            <Add
              className="close-icon"
              size={26}
              onClick={() => setActiveImage(null)}
            />
            <ArrowRight2
              className="next-icon"
              size={26}
              onClick={() =>
                setActiveImage(
                  activeImage === images.length ? activeImage : activeImage + 1
                )
              }
            />
            <ArrowLeft2
              className="prev-icon"
              size={26}
              onClick={() =>
                setActiveImage(activeImage === 1 ? 1 : activeImage - 1)
              }
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Gallery;
