import { ArrowRight, ArrowUp, Link2 } from "iconsax-react";
import React, { useEffect, useState } from "react";
import "./testimonial.css";
import { testimonialsDetail as testimonials } from "../Landing/landingInfo";
import quoteIcon from "../../assets/icons/quote.svg";
import promo from "../../assets/videos/ppt.mp4";
import { Link } from "react-router-dom";
import { Brand, brandList } from "../../utils/brands";

interface Testimonial {
  content: string;
  byWhom: string;
  jobRole: string;
  lineClamp: number;
  jobWhere: string;
  photo: string;
}

interface Client {
  name: string;
  url?: string;
  image: string;
}

const Testimonials = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <div className="testimonial-container w-full flex flex-col">
      <div className="testimonial-hero rounded-xl flex items-center">
        <div className="yt-video overflow-hidden w-2/5 rounded-lg flex flex-col h-72 justify-center items-center bg-black">
          <video
            src={promo}
            autoPlay
            loop
            muted
            className="h-full w-full object-cover"
          ></video>
        </div>
        <div className="lg:w-16 w-0"></div>
        <div className="testimonial-text flex-1">
          {isMobile ? (
            <p className="text-xl h1 text-white">
              Over {brandList.length - 1} companies use and like WebEye IoT in their daily
              operations
            </p>
          ) : (
            <p className="text-xl h1 text-white">
              Over 15 companies use and like <br />
              WebEye IoT in their daily operations
            </p>
          )}

          <div className="overflow-hidden w-full my-8 relative">
            <div className="marquee-inner"></div>
            <div className="flex w-full marquee-content">
              {brandList.slice(0, 6).map((item, index) => {
                return (
                  <img className="w-32" src={item.imageWhite} key={index} />
                );
              })}
              {brandList.slice(0, 6).map((item, index) => {
                return (
                  <img className="w-32" src={item.imageWhite} key={index} />
                );
              })}
              {brandList.slice(0, 6).map((item, index) => {
                return (
                  <img className="w-32" src={item.imageWhite} key={index} />
                );
              })}
            </div>
          </div>

          <a
            href="clients-testimonials#clients"
            className="flex items-center text-white underline underline-offset-4"
          >
            See all clients
          </a>
        </div>
      </div>
      <div className="testimony w-full flex flex-col py-6 p-side">
        <p className="h1 tan">
          What our clients say about{" "}
          <span className="fancy-underline h1 tan">WebEye</span>{" "}
        </p>
        <p className="grey text-md mt-4">
          Read what our customers have to say about WebEye IoT's experience
        </p>

        <div className="w-full mt-8 flex flex-col sm:flex-row justify-between">
          <div className="flex-1 flex flex-col">
            {testimonials.slice(0, 3).map((t, index) => {
              return <TestimonialItem testimonial={t} index={index} />;
            })}
          </div>
          <div className="w-0 sm:w-10"></div>
          <div className="flex-1 flex flex-col">
            {testimonials.slice(3, 6).map((t, index) => {
              return <TestimonialItem testimonial={t} index={index} />;
            })}
          </div>
          {/* <div className="w-0 sm:w-10"></div>
          <div className="flex-1 flex flex-col">
            {testimonials.slice(6, 9).map((t, index) => {
              return <TestimonialItem testimonial={t} index={index} />;
            })}
          </div> */}
        </div>
      </div>
      <div id="clients" className="w-full flex flex-col py-6 p-side">
        <p className="h1 tan">Our Clientele</p>
        <p className="grey text-md mt-4">
          WebEye is used by over 15+ companies all over India
        </p>
        <div className="client-grid">
          {brandList.map((client, index) => (
            <ClientItem client={client} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

const TestimonialItem = ({
  testimonial,
  index,
}: {
  testimonial: Testimonial;
  index: number;
}) => {
  return (
    <div
      className="landing-testimonial-item p-6 my-5 rounded-lg bg-slate-100 flex flex-col"
      key={index}
    >
      <img src={quoteIcon} alt="quote" width={40} className="mt-4" />
      <p className="normal-text-lg dark-grey mt-6 landing-testimonial-content">
        {testimonial.content}
      </p>
      <div className="flex items-center mt-6">
        <div className="w-10 h-10 overflow-hidden rounded-full mr-3 flex items-center justify-center">
          <img
            src={testimonial.photo}
            alt="photo"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="flex flex-col">
          <p className="landing-testimonial-name normal-text-md tan">
            {testimonial.byWhom}
          </p>
          <p className="landing-testimonial-company normal-text-sm grey">
            {testimonial.jobRole} at{" "}
            <span className="primary">{testimonial.jobWhere}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const ClientItem = ({ client }: { client: Brand }) => {
  const [hover, setHover] = useState(false);
  if (client.url) {
    if (client.url.endsWith("/")) {
      client.url = client.url.substring(0, client.url.length - 1);
    }
  }
  return (
    <a
      href={client.url || ""}
      target="_blank"
      className={client.url ? "" : "url-disabled"}
    >
      <div
        className={`client-item h-full border-white p-5 flex flex-col border ${
          hover ? "bg-slate-200" : "bg-slate-100"
        } transition-all cursor-pointer`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {client.imageBlack && client.imageWhite && (
          <div
            className="client-item-image"
            style={{
              backgroundImage: hover
                ? `url(${client.imageBlack})`
                : `url(${client.imageBlack})`,
            }}
          ></div>
        )}

        <p
          className={`${
            client.url || client.imageBlack ? "mt-6" : ""
          } tan normal-text-lg font-xbold`}
        >
          {client.name}
        </p>
        {client.url && (
          <p className="normal-text-sm mt-2 primary flex items-center">
            {client.url
              .replace("www.", "")
              .replace("https://", "")
              .replace("http://", "")}
            &nbsp;&nbsp;
            <ArrowUp style={{ transform: "rotate(45deg)" }} size={16} />
          </p>
        )}
      </div>
    </a>
  );
};
