import React, { useEffect, useRef, useState } from "react";
import "./about.css";
import vid from "../../assets/videos/demo.mp4";
import play from "../../assets/icons/play.svg";
import worksDemo from "../../assets/images/about/demo_works.svg";
import worksDemoMobile from "../../assets/images/about/demo_works_mobile.svg";
import { Link } from "react-router-dom";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import proc1 from "../../assets/images/landing/proc1.svg";
import StatBox from "../../components/StatBox/StatBox";



interface FAQ {
  q: string;
  a: string;
}


const faqs = [
  {
    q: "What is the return on investment for WebEye?",
    a: `WebEye provides a very economic and efficient return on investment. You can easily find out your approximate ROI using our <a href="/roi" class="primary dotted-underline"> free ROI calculator </a>`,
  },
  {
    q: "Lorem ipsum dolor sit amet ?",
    a: "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br><br> Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    q: "Lorem ipsum dolor sit amet ?",
    a: "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    q: "Lorem ipsum dolor sit amet ?",
    a: "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    q: "Lorem ipsum dolor sit amet ?",
    a: "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const processFlow = [
  {
    title: "Data Collection",
    desc: "Sensors installed on machines in the factory collect data from the machines and share it with the cloud-based WebEye Gateway",
  },
  {
    title: "Data Storage and Visualization",
    desc: "This data is stored on cloud, and you get to visualize the operational status of all the machines remotely, and in real-time",
  },
  {
    title: "Analysis Models",
    desc: "Analytical and predictive models help to provide accurate analysis, and help to predict any points of failure",
  },
];

const About = () => {

  const processFlowRef = useRef<HTMLDivElement>(null);
  const [activeProcess, setActiveProcess] = useState(0);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setInterval(() => {
      setActiveProcess((activeProcess) => (activeProcess + 1) % 3);
      console.log(activeProcess);
    }, 5000);
  }, []);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <div className="about-container">
      <div className="about-hero">
        <div className="about-hero-bg"></div>
        <div className="about-hero-left">
          <h1 className="tan leading-relaxed font-bold text-4xl tracking-tight">
            See what makes{" "}
            <span className="fancy-underline tan text-4xl tracking-tight">
              WebEye IoT
            </span>{" "}
            the go-to choice
          </h1>
          <div className="flex flex-col">
            <p
              className={`normal-text-lg web-only grey ${isMobile ? "my-4" : "my-8"
                } leading-loose`}
            >
              WebEye IOT is an Industry 4.0 based product that remotely monitors
              systems, reports machine activites to you on your mobile phone,
              tab or PC.
            </p>
            <p
              className={`normal-text-lg grey ${isMobile ? "my-4" : "mb-8"
                } leading-loose`}
            >
              {isMobile
                ? `WebEye IOT is developed by Sanmin Technologies Pvt Ltd, based in
            Pune, India and in Japan. Lorem ipsum dolor sit amet consectetur
            dolor amer uber konnen wir with dich eine Zuchenstreite bechreiften.
            `
                : `WebEye IOT is developed by Sanmin Technologies Pvt Ltd, based in
            Pune, India and in Japan. Lorem ipsum dolor sit amet consectetur
            dolor amer uber konnen wir with dich eine Zuchenstreite bechreiften.
            Um die StraBe will sich die Haus im eine folodsech denen.`}
            </p>
            <p
              className={`normal-text-lg grey ${isMobile ? "mb-4" : "mb-8"
                } leading-loose`}
            >
              Lorem ipsum dolor sit amet consectetur dolor amer uber konnen wir
              with dich eine Zuchenstreite bechreiften. Um die StraBe will sich
              die Haus im eine folodsech denen.
            </p>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center">
              <Link to="/contact-us">
                <button className="primary-button big">Contact Us</button>
              </Link>
              <div className="w-4"></div>
              <Link to="/roi">
                <button className="secondary-button big">
                  Get ROI with WebEye
                </button>
              </Link>
            </div>
            {isMobile && (
              <FiChevronDown
                size={20}
                className="grey"
                style={{ marginTop: 20 }}
                onClick={() =>
                  window.scroll({
                    top: window.innerHeight / 2,
                    left: 0,
                    behavior: "smooth",
                  })
                }
              />
            )}
          </div>
        </div>
        <div className="about-hero-right">
          <div className="video-covering">
            <div className="video-overlay">
              <div className="play-button">
                <img src={play} alt="play" />
              </div>
            </div>
            <video
              src={vid}
              autoPlay={false}
              loop
              muted
              className="object-fill"
            ></video>
          </div>
        </div>
        <img
          src={isMobile ? worksDemoMobile : worksDemo}
          className="works-demo"
          alt="works-demo"
        />
      </div>

      <div className="process-flow" ref={processFlowRef}>
        <div className="process-flow-sticky">
          <p className="caps primary text-left">How it Works</p>
          <br />
          <p className="h1">
            <span className="h1 grey">How does</span>{" "}
            <span className="h1 tan">
              <span className="fancy-underline h1 tan"> WebEye IoT </span> work?
            </span>
          </p>
          <br />
          <br />
          <div className="process-flow-inner">
            <div className="process-flow-left">
              <div className="process-flow-left-inner">
                <img
                  src={proc1}
                  alt=""
                  className={`process-flow-img-${activeProcess}`}
                />
              </div>
            </div>
            <div className="process-flow-right">
              {processFlow.map((pro, index) => {
                return (
                  <div
                    onClick={() => setActiveProcess(index)}
                    className={
                      index === activeProcess
                        ? "process-flow-box active"
                        : "process-flow-box"
                    }
                    key={index}
                  >
                    <h1>{pro.title}</h1>
                    <p>{pro.desc}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>




      <StatBox />
      <h1 className="h1 tan mb-6 mt-10 p-side" id="faq">
        Frequently Asked Questions
      </h1>
      <div className="my-6 flex flex-col p-side">
        {faqs.map((f, index) => (
          <FAQCard faq={f} key={index} />
        ))}
      </div>
    </div>
  );
};



const FAQCard = ({ faq }: { faq: FAQ }) => {
  return (
    <div className="faq-card">
      <p className="question">{faq.q}</p>
      <p className="answer" dangerouslySetInnerHTML={{ __html: faq.a }}></p>
    </div>
  );
};
export default About;
