import { ArrowDown2 } from "iconsax-react";
import React, { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import matchPath from "../../utils/matchPath";
import "./header.css";
import OutsideClickHandler from "react-outside-click-handler";
import { caseStudies } from "../../screens/CaseStudies/CaseStudies";
import { FiMenu, FiX } from "react-icons/fi";

interface CaseStudy {
  title: string;
  content: string[];
  image: string;
  index: number;
  href: string;
}

interface HeaderLink {
  title: string;
  pathTo: string;
  submenu?: Array<CaseStudy>;
}

interface MobileHeaderLink {
  title: string;
  pathTo: string;
}

const headerLinks: Array<HeaderLink> = [
  {
    title: "Case Studies",
    pathTo: "/case-studies",
    submenu: caseStudies,
  },
  { title: "About and FAQ", pathTo: "/about" },
  { title: "Testimonials", pathTo: "/clients-testimonials" },
];

const mobileHeaderLinks: Array<MobileHeaderLink> = [
  {
    title: "Case Studies",
    pathTo: "/case-studies",
  },
  {
    title: "Gallery",
    pathTo: "/gallery",
  },
  {
    title: "Clients and Testimonials",
    pathTo: "/clients-testimonials",
  },
  {
    title: "About and FAQ",
    pathTo: "/about",
  },
  {
    title: "Contact Us",
    pathTo: "/contact-us",
  },
];

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);
  const location = useLocation();

  const showSubmenu = (): void => {
    setSubmenuOpen(true);
    console.log("showing submenu");
  };

  const hideSubmenu = (): void => {
    setSubmenuOpen(false);
  };

  console.log(location);
  return (
    <Fragment>
      <div
        className={`header-outer-container ${!isMobile &&
          (location.pathname === "/" || location.pathname === "/about") &&
          "no-bg"
          }`}
      >
        <div
          className={
            mobileMenuOpen
              ? "header-inner-container mb-open"
              : "header-inner-container"
          }
        >
          <div className="header-left w-full">
            <Link to="/">
              <div className="header-logo">
                <div className="webeye-logo"></div>
                <p className="webeye-title">WebEye IoT</p>
              </div>
            </Link>
            {isMobile ? (
              !mobileMenuOpen ? (
                <FiMenu
                  className="grey"
                  size={26}
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                />
              ) : (
                <FiX
                  className="grey"
                  size={26}
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                />
              )
            ) : (
              <ul className="w-full flex justify-center">
                {headerLinks.map((headerItem: HeaderLink, index: number) => {
                  let interval: NodeJS.Timeout;
                  return (
                    <Fragment>
                      <li
                        key={index}
                        onMouseEnter={() => {
                          interval = setTimeout(() => {
                            if (headerItem.submenu) {
                              showSubmenu();
                            }
                          }, 500);
                        }}
                        onMouseLeave={() => clearInterval(interval)}
                        className={`${matchPath(headerItem.pathTo) ? "active-link" : ""
                          }`}
                      >
                        <Link to={headerItem.pathTo}>{headerItem.title}</Link>
                        {headerItem.submenu && (
                          <Fragment>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ArrowDown2 className="-ml-4 mr-4" size={13} />
                          </Fragment>
                        )}
                      </li>
                      {headerItem.submenu && submenuOpen && (
                        <OutsideClickHandler onOutsideClick={hideSubmenu}>
                          <div className="header-submenu">
                            {headerItem.submenu.map(
                              (
                                submenuItem: CaseStudy,
                                submenuIndex: number
                              ) => {
                                return (
                                  <Link
                                    className="flex flex-row py-4 px-6 hover:bg-gray-100 transition-all"
                                    to={"/case-studies/" + submenuItem.href}
                                    key={submenuIndex}
                                    onClick={hideSubmenu}
                                  >
                                    <img
                                      src={submenuItem.image}
                                      alt="img"
                                      style={{
                                        width: 100,
                                        objectFit: "cover",
                                        borderRadius: 5
                                      }}
                                    />
                                    <div className="ml-6 flex flex-col">
                                      <h4 className="tan text-sm font-semibold mb-1">
                                        {submenuItem.title}
                                      </h4>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            submenuItem.content.join("\n").slice(0, 80) +
                                            "...",
                                        }}
                                        className="grey normal-text-xsm"
                                      ></p>
                                    </div>
                                  </Link>
                                );
                              }
                            )}
                          </div>
                        </OutsideClickHandler>
                      )}
                    </Fragment>
                  );
                })}
              </ul>
            )}
          </div>
          <div className="header-right">
            <Link to="/contact-us">
              <button className="tertiary-button">Contact Us</button>
            </Link>
          </div>
        </div>
      </div>
      {isMobile && (
        <div
          className={
            mobileMenuOpen
              ? "header-mobile-cont"
              : "header-mobile-cont header-mobile-cont-closed"
          }
        >
          <div className="header-mobile-cont-inner">
            <ul>
              {mobileHeaderLinks.map(
                (link: MobileHeaderLink, index: number) => {
                  return (
                    <li onClick={() => setMobileMenuOpen(false)}>
                      <Link to={link.pathTo} className="grey" key={index}>
                        {link.title}
                      </Link>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Header;
