import React from "react";
import quoteIcon from "../../assets/icons/quote.svg";
// import { Testimonial } from "../../screens/Landing/landingInfo";
import "./landing-testimonial.css";

interface Testimonial {
  content: string;
  byWhom: string;
  jobRole: string;
  lineClamp: number;
  jobWhere: string;
  photo: string;
}

const LandingTestimonial = ({
  testimonial,
  index,
}: {
  testimonial: Testimonial;
  index: number;
}) => {
  return (
    <div className="landing-testimonial-item flex flex-col" key={index}>
      <img src={quoteIcon} alt="quote" width={30} />
      <p
        className="normal-text-sm grey mt-4 landing-testimonial-content"
        style={{
          lineClamp: testimonial.lineClamp,
          WebkitLineClamp: testimonial.lineClamp,
        }}
      >
        {testimonial.content}
      </p>
      <div className="flex items-center mt-4">
        <div className="w-10 h-10 overflow-hidden rounded-full mr-3 flex items-center justify-center">
          <img
            src={testimonial.photo}
            alt="photo"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="flex flex-col">
          <p className="landing-testimonial-name normal-text-sm tan">
            {testimonial.byWhom}
          </p>
          <p className="landing-testimonial-company normal-text-xsm grey">
            {testimonial.jobRole} at{" "}
            <span className="primary">{testimonial.jobWhere}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingTestimonial;
