import emailjs from '@emailjs/browser'
import { FormEvent } from 'react'

export const sendGetBackEmail = (email: string, e: FormEvent) => {
    e.preventDefault()
    emailjs.send(
        'webeye',
        "template_8rptwha",
        {
          email: email
        },
        "RmXrqkPr0WimdRNOe"
      ).then(res => {
        console.log('Email successfully sent!', res)
        alert("Message sent successfully!")
      })
        .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
}

