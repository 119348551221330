import bajaj from "../assets/images/brands/bajaj.svg";
import showadenkoW from "../assets/images/brands/showadenkoW.svg";
import showadenkoB from "../assets/images/brands/showadenkoB.svg";
import samarthB from '../assets/images/brands/samarthB.svg'
import samarthW from '../assets/images/brands/samarthW.svg'
import sinewaveB from '../assets/images/brands/sinewaveB.svg'
import sinewaveW from '../assets/images/brands/sinewaveW.svg'
import vrB from '../assets/images/brands/vrB.svg'
import vrW from '../assets/images/brands/vrW.svg'
import jamcoW from "../assets/images/brands/jamcoW.svg";
import jamcoB from '../assets/images/brands/jamcoB.svg'
import tsquareB from "../assets/images/brands/tsquareB.svg";
import tsquareW from '../assets/images/brands/tsquareW.svg'
import mayurB from "../assets/images/brands/mayurB.svg";
import mayurW from '../assets/images/brands/mayurW.svg'
import sigmaweldB from "../assets/images/brands/sigmaweldB.svg";
import sigmaweldW from '../assets/images/brands/sigmaweldW.svg'
import chaphekarB from "../assets/images/brands/chapekarB.svg";
import chaphekarW from '../assets/images/brands/chapekarW.svg'
import globotronixB from "../assets/images/brands/globatronixB.svg";
import globotronixW from '../assets/images/brands/globatronixW.svg'
import abaqusB from "../assets/images/brands/abaqusB.svg";
import abaqusW from '../assets/images/brands/abaqusW.svg'

interface Brand {
    name: string,
    imageWhite?: string,
    imageBlack?: string,
    url?: string
}

export const brandList: Array<Brand> = [
    {
        name: 'Jamco Corp, Japan',
        imageWhite: jamcoW,
        imageBlack: jamcoB,
        url: 'https://www.jamco.co.jp/en/index.html'
    },
    {
        name: 'Showadenko',
        imageWhite: showadenkoW,
        imageBlack: showadenkoB,
        url: 'https://www.sdk.co.jp/english/'
    },
    {
        name: "Chapekhar Suspensions, India",
        imageBlack: chaphekarB,
        imageWhite: chaphekarW,
        url: "http://www.csplind.com/"
    },
    {
        name: "Abaqus Japan",
        imageBlack: abaqusB,
        imageWhite: abaqusW
    },
    {
        name: "Sinewave Engineering",
        imageBlack : sinewaveB,
        imageWhite: sinewaveW,
        url: "http://www.sinewaveengineering.com/"
    },
   
    {
        name: "Globatronix, India",
        imageBlack: globotronixB,
        imageWhite: globotronixW,
        url: "https://www.globatronix.com/en/"
    },
    {
        name: "Samarth Engineering",
        imageBlack : samarthB,
        imageWhite: samarthW,
        url: "http://www.samarthengg.co.in/"
    },
   
    {
        name: "V R Coatings, India",
        imageBlack : vrB,
        imageWhite: vrW,
        url: "https://www.vrcoatings.com/"
    },
    {
        name: "TSquare Tools, India",
        imageBlack: tsquareB,
        imageWhite: tsquareW,
        url: "http://tsquaretools.in/index.html"
    },
    {
        name: "Mayur Industries, Waluj",
        imageBlack: mayurB,
        imageWhite: mayurW,
    },
    {
        name: "Sigma Weld, Mumbai",
        imageBlack: sigmaweldB,
        imageWhite: sigmaweldW,
        url: "http://www.sigmaweld.com/"
    },
    {
        name: 'Touei Densesu, Japan',
    },
   {
    name: "SJF Private Limited, India"
   },
   {
    name: "Seraphic Casting, India"
   },
   {
    name: "KC Toolroom Pvt. Ltd"
   },
   {
    name: "Sai Engineering, Pune"
   }
]

export type {
    Brand
}